import React from 'react';
import Head from 'next/head';
import { LOCALE_TO_LANG_MAPPING } from 'setup/consts/common';
import { HrefLangsProps } from './types';
import { useHreflangs } from './hooks';

const HrefLangs: React.FC<HrefLangsProps> = ({ alternateLanguages }) => {
  const hreflangs = useHreflangs(alternateLanguages);

  if (!hreflangs) {
    return null;
  }

  const { alternates, defaultHreflang, regionIndependentHrefs } = hreflangs;

  return (
    <Head>
      <link rel="alternate" hrefLang="x-default" href={defaultHreflang} />
      {alternates.map(({ locale, href }) => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={LOCALE_TO_LANG_MAPPING[locale]?.toLowerCase()}
          href={href}
        />
      ))}
      {regionIndependentHrefs.map(({ locale, href }) => (
        <link
          key={`region-${locale}`}
          rel="alternate"
          hrefLang={locale?.toLowerCase()}
          href={href}
        />
      ))}
    </Head>
  );
};

export default HrefLangs;
