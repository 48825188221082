import { useRouter } from 'next/router';
import { useMemo } from 'react';
import urljoin from 'url-join';
import { getConfig } from 'utils/config';
import { AlternateLanguagesSlug } from 'utils/prismic/types';
import { DEFAULT_HREFLANGS_LOCALES } from './consts';
import { AlternateLink, UseHreflangsReturnType } from './types';
import { ensureCorrectHreflangTrailingSlash } from './utils';

const regionIndependentLocale = [
  {
    lang: 'en',
    slug: '/',
  },
  {
    lang: 'fr',
    slug: '/fr/',
  },
];

export const useHreflangs = (
  alternateLanguages: AlternateLanguagesSlug[] | undefined,
): UseHreflangsReturnType | undefined => {
  const router = useRouter();

  return useMemo(() => {
    const { asPath, locales, defaultLocale, locale: currentLocale } = router;

    const { appDomain } = getConfig().publicRuntimeConfig;

    if (!alternateLanguages) {
      return {
        defaultHreflang: ensureCorrectHreflangTrailingSlash(
          urljoin(appDomain, asPath),
        ),
        regionIndependentHrefs:
          regionIndependentLocale.map(({ lang, slug }) => ({
            locale: lang,
            href: ensureCorrectHreflangTrailingSlash(urljoin(appDomain, slug)),
          })) || [],
        alternates:
          locales?.map((locale) => ({
            locale,
            href: ensureCorrectHreflangTrailingSlash(
              urljoin(
                appDomain,
                locale === defaultLocale ? '' : `${locale}/`,
                asPath,
              ),
            ),
          })) || [],
      };
    }

    const alternates: AlternateLink[] = [
      {
        locale: currentLocale || '',
        href: ensureCorrectHreflangTrailingSlash(
          urljoin(
            appDomain,
            currentLocale === defaultLocale ? '' : `${currentLocale}/`,
            asPath,
          ),
        ),
      },

      ...alternateLanguages.map(({ lang, slug }) => ({
        locale: lang,
        href: ensureCorrectHreflangTrailingSlash(
          urljoin(
            appDomain,
            lang === defaultLocale || lang === '' ? '' : `${lang}/`,
            slug,
          ),
        ),
      })),
    ];

    if (alternates.length < 2) {
      return undefined;
    }

    const defaultHreflang = DEFAULT_HREFLANGS_LOCALES.reduce<
      string | undefined
    >((result, defaultHreflangsLocale) => {
      if (result) {
        return result;
      }

      return alternates.find(({ locale }) => locale === defaultHreflangsLocale)
        ?.href;
    }, undefined);

    if (!defaultHreflang) {
      return undefined;
    }

    return {
      regionIndependentHrefs: regionIndependentLocale.map(({ lang, slug }) => ({
        locale: lang,
        href: ensureCorrectHreflangTrailingSlash(urljoin(appDomain, slug)),
      })),
      alternates,
      defaultHreflang,
    };
  }, [alternateLanguages, router]);
};
