import { PrismicImage } from 'utils/prismic/types';
import { SectionBackground } from './enums';

export const hasFullWidthBackgroundImage = (
  backgroundType: string | undefined,
  backgroundImage: PrismicImage | undefined,
): backgroundImage is PrismicImage =>
  backgroundType === SectionBackground.Image &&
  !!backgroundImage &&
  Object.keys(backgroundImage).length > 0;
