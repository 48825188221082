import { LOCALES } from '../../../config/next/languages';

export const COUNTRY_TO_LOCALE_PREFIX_MAPPING: Partial<MappedObject<string>> = {
  Canada: '',
  Australia: 'au',
  'New Zealand': 'nz',
};

export const LOCALE_TO_LANG_MAPPING: Partial<MappedObject<string>> = {
  [LOCALES.ca]: 'en-CA',
  [LOCALES.fr]: 'fr-CA',
  [LOCALES.au]: 'en-AU',
  [LOCALES.nz]: 'en-NZ',
};

export enum CryptoCurrency {
  BTC = 'btc',
  ETH = 'eth',
  LTC = 'ltc',
}

export const ATM_COUNTRY_TO_LOCALE_PREFIX_MAPPING: Partial<
  MappedObject<string>
> = {
  Canada: 'ca',
  Australia: 'au',
  'New Zealand': 'nz',
};
