import React, { Fragment } from 'react';
import { NextPage } from 'next';
import Head from 'next/head';

import HrefLangs from 'components/HrefLangs';
import PageSectionsRenderer from 'components/PageSectionsRenderer';
import Script from 'components/Script';
import { CatchAllInitialProps } from './types';

const CatchAll: NextPage<CatchAllInitialProps> = ({
  pageData: {
    title,
    description,
    seoTitle,
    seoDescription,
    pageSections,
    openGraphPictureUrl,
    code,
    alternateLanguages,
    searchEnginesIndexing,
  },
  structuredData,
}) => (
  <Fragment>
    <Head>
      <title>
        {seoTitle ||
          title ||
          'Bitcoin ATM - Buy and Sell Bitcoin with Cash - Low Fees | Localcoin'}
      </title>
      {seoDescription || description ? (
        <meta name="description" content={seoDescription || description} />
      ) : null}
      {openGraphPictureUrl ? (
        <meta name="og:image" content={openGraphPictureUrl} />
      ) : null}
      {!searchEnginesIndexing && <meta name="robots" content="noindex" />}
      {structuredData?.faqSchema && (
        <script
          id="faqSchema"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: structuredData.faqSchema,
          }}
        />
      )}
    </Head>

    <HrefLangs {...{ alternateLanguages }} />
    <PageSectionsRenderer sections={pageSections} />
    {code && <Script>{code}</Script>}
  </Fragment>
);

//////////////////////
/////////////////////////////////////////////////////////
//////////

export default CatchAll;
